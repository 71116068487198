import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Customer from "./components/Customer";
import Payments from "./components/Payments";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { createContext, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import AdminDashboard from "./components/AdminDashboard";
import Distributor from "./components/Distributor";
import DistributorwiseOverdue from "./components/DistributorwiseOverdue";
import DistributorwiseOverdueEMIs from "./components/DistributorwiseOverdueEMIs";
import CustomersOverdue from "./components/CustomersOverdue";
import EMIsOverdue from "./components/EMIsOverdue";
import "./custom.scss";
import GreattrLogo from "./components/assets/images/logo-DH.png";
import Signin from "./components/Signin";
import { LoginContext } from "./components/LTC";
import Loggedout from "./components/Loggedout";
import { useEffect } from "react";
import Charts from "./components/Charts";
import Cookies from "js-cookie";
import DistributorData from "./components/DistributorData";
import Upload from "./components/Upload";
import SalesOfficer from "./components/SalesOfficer";
import TerritoryManager from "./components/TerritoryManager";
function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");

  const [searchValue, setSearchValue] = useState("");
  const [searchValueTM, setSearchValueTM] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsTM, setFilteredItemsTM] = useState([]);

  const tmData = JSON.parse(sessionStorage.getItem("TMData"));
  const soData = JSON.parse(sessionStorage.getItem("SOData"));

  const UserRole = useRef(Cookies.get("userRole"));

  const navigate = useNavigate();

  // Function to handle tab selection for sorting
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    // Filter the items based on the search value
    const filtered = JSON.parse(sessionStorage.getItem("SOData")).filter((x) =>
      x.name_of_sales_officer.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleSearchChangeTM = (event) => {
    const value = event.target.value;
    setSearchValueTM(value);

    // Filter the items based on the search value
    const filtered = JSON.parse(sessionStorage.getItem("TMData")).filter((x) =>
      x.name_of_territory_manager.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItemsTM(filtered);
  };
  useEffect(() => {
    // Load count from sessionStorage when the component mounts
    const savedCount = sessionStorage.getItem("loggedin");
    if (savedCount) {
      setIsLoggedIn(savedCount);
    } else {
      // If sessionStorage doesn't have "loggedin", check cookies
      const isLoggedInCookie = getCookie("loggedin");
      if (isLoggedInCookie) {
        setIsLoggedIn(isLoggedInCookie);
      }
    }
  }, []);

  const location = useLocation();
  return (
    <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <Routes>
        <Route path="/" element={<Signin />} />
      </Routes>
      {isLoggedIn ? (
        <>
          {Cookies.get("userRole") === "Distributor" ? (
            <Navbar bg="dark" variant="dark" className="navbg" expand="lg">
              <Navbar.Brand href="/dashboard">
                <img src={GreattrLogo} alt="GreattrLogo" width={120} />

              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <LinkContainer to="/dashboard">
                    <Nav.Link>Dashboard</Nav.Link>
                  </LinkContainer>

                  <NavDropdown
                    className="custom-dropdown"
                    title="Customer"
                    id="customer-dropdown"
                  >
                    <LinkContainer to="/Customer">
                      <NavDropdown.Item>Customers</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/CustomersOverdue">
                      <NavDropdown.Item>
                        Customers Loan Disbursed
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <LinkContainer to="/EMIsOverdue">
                    <Nav.Link>EMIs</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/Charts">
                    <Nav.Link>Charts</Nav.Link>
                  </LinkContainer>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end">
                <LinkContainer
                  to="/Upload"
                  style={{
                    marginRight: "2%",
                  }}
                >
                  <Nav.Link className="ml-auto">
                    <div
                      className="d-flex "
                      style={{
                        alignItems: "center",
                        marginRight: "20%",
                        width: "100%",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "1rem",
                          padding: 0,
                          margin: 0,
                          textAlign: "end",
                          marginRight: 5,
                          color: "white",
                        }}
                      >
                        {" "}
                        Upload New Customers
                      </h2>
                      <FontAwesomeIcon
                        icon={faFileUpload}
                        size="2x"
                        style={{
                          color: "white",
                          cursor: "pointer",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                    </div>
                  </Nav.Link>
                </LinkContainer>
                <NavDropdown
                  className="custom-dropdown"
                  title={
                    "Logged in as " +
                    (Cookies.get("userName") ||
                      sessionStorage.getItem("userName")) +
                    " " +
                    (Cookies.get("userRole") || Cookies.get("userRole"))
                  }
                  id="customer-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      setIsLoggedIn(false);
                      Cookies.remove("loggedin", { path: "/" });
                      Cookies.remove("userRole", { path: "/" });
                      Cookies.remove("userMobile", { path: "/" });
                      Cookies.remove("userMobileTM", { path: "/" });
                      Cookies.remove("UserName", { path: "/" });
                      Cookies.remove("password", { path: "/" });
                      sessionStorage.clear("loggedin");
                      sessionStorage.clear();

                      navigate("/");
                    }}
                  >
                    {" "}
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Navbar.Collapse>
            </Navbar>
          ) : (
            <Navbar bg="dark" variant="dark" className="navbg" expand="lg">
              <Navbar.Brand href="/Dashboard">
                <img src={GreattrLogo} alt="GreattrLogo" width={120} />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <LinkContainer to="/Dashboard">
                    <Nav.Link>Dashboard</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/TerritoryManager">
                    <Nav.Link>TM Overview</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/SalesOfficer">
                    <Nav.Link>SO Overview</Nav.Link>
                  </LinkContainer>
                  <NavDropdown title="Distributor" id="distributor-dropdown">
                    <LinkContainer to="/Distributor">
                      <NavDropdown.Item>Distributor</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/DistributorData">
                      <NavDropdown.Item>Distributor Overview</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/DistributorwiseOverdue">
                      <NavDropdown.Item>
                        Distributor wise Overdue Customers
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/DistributorwiseOverdueEMIs">
                      <NavDropdown.Item>
                        Distributor wise Overdue EMIs
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavDropdown
                    className="custom-dropdown"
                    title="Customer"
                    id="customer-dropdown"
                  >
                    <LinkContainer to="/Customer">
                      <NavDropdown.Item>Customers</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/CustomersOverdue">
                      <NavDropdown.Item>
                        Customers Loan Disbursed
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <LinkContainer to="/EMIsOverdue">
                    <Nav.Link>EMIs</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/Charts">
                    <Nav.Link>Charts</Nav.Link>
                  </LinkContainer>
                  {sessionStorage.getItem("userRole") !== "TM" && (
                    <NavDropdown
                      className="custom-dropdown"
                      title="TM sort"
                      id="customer-dropdown"
                    >
                      {/* {sessionStorage.getItem("TMData")
                      ? JSON.parse(sessionStorage.getItem("TMData")).map(
                        (x) => (
                          <NavDropdown.Item key={x.id}>
                            <Navbar.Text style={{ color: "black" }}>
                              {x.name_of_territory_manager}
                            </Navbar.Text>
                          </NavDropdown.Item>
                        )
                      )
                      : ""} */}
                      <NavDropdown.Item
                        key={0}
                        onClick={() => {
                          handleTabClick("All");
                          Cookies.set("SortString", `All TMs`, {
                            path: "/",
                          });
                          Cookies.remove("userMobile", { path: "/" });
                          window.location.reload();
                        }}
                        style={{
                          backgroundColor:
                            Cookies.get("userMobile") === null
                              ? "#573a87"
                              : "transparent",
                          color: "black",
                        }}
                      >
                        <Navbar.Text style={{ color: "black" }}>
                          All
                        </Navbar.Text>
                      </NavDropdown.Item>
                      <div className="search-container">
                        <input
                          type="text"
                          placeholder="Search..."
                          style={{
                            paddingTop: "5px",
                            marginTop: "1px",
                          }}
                          value={searchValueTM}
                          onChange={handleSearchChangeTM}
                        />
                      </div>
                      {sessionStorage.getItem("TMData")
                        ? (searchValueTM === ""
                          ? JSON.parse(sessionStorage.getItem("TMData"))
                          : filteredItemsTM
                        )
                          .sort((a, b) =>
                            a.name_of_territory_manager.localeCompare(
                              b.name_of_territory_manager
                            )
                          )
                          .map((x, index) => (
                            <NavDropdown.Item
                              key={index + 1}
                              onClick={() => {
                                handleTabClick(x.name_of_territory_manager);
                                Cookies.set("userMobile", x.tm_mobile_no, {
                                  path: "/",
                                });

                                Cookies.set(
                                  "SortString",
                                  `TM('${x.name_of_territory_manager}')`,
                                  {
                                    path: "/",
                                  }
                                );
                                window.location.reload();
                              }}
                              style={{
                                backgroundColor:
                                  Cookies.get("userMobile") === x.tm_mobile_no
                                    ? "#573a87"
                                    : "transparent",
                                color: "black",
                              }}
                            >
                              <Navbar.Text style={{ color: "black" }}>
                                {x.name_of_territory_manager.toUpperCase()}
                              </Navbar.Text>
                            </NavDropdown.Item>
                          ))
                        : null}
                    </NavDropdown>
                  )}
                  <NavDropdown
                    className="custom-dropdown"
                    title="SO sort"
                    id="customer-dropdown"
                  >
                    <NavDropdown.Item
                      key={0}
                      onClick={() => {
                        handleTabClick("All");
                        Cookies.set("userMobile", Cookies.get("userMobileTM"), {
                          path: "/",
                        });
                        Cookies.set("SortString", `All SOs`, {
                          path: "/",
                        });
                        window.location.reload();
                      }}
                      style={{
                        backgroundColor:
                          Cookies.get("userMobile") ===
                            Cookies.get("userMobileTM")
                            ? "#573a87"
                            : "transparent",
                        color: "black",
                      }}
                    >
                      <Navbar.Text style={{ color: "black" }}>All</Navbar.Text>
                    </NavDropdown.Item>
                    <div className="search-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        style={{
                          paddingTop: "5px",
                          marginTop: "1px",
                        }}
                        value={searchValue}
                        onChange={handleSearchChange}
                      />
                    </div>
                    {sessionStorage.getItem("SOData")
                      ? (searchValue === ""
                        ? JSON.parse(sessionStorage.getItem("SOData"))
                        : filteredItems
                      )
                        .sort((a, b) =>
                          a.name_of_sales_officer.localeCompare(
                            b.name_of_sales_officer
                          )
                        )
                        .map((x, index) => (
                          <NavDropdown.Item
                            key={index + 1}
                            onClick={() => {
                              handleTabClick(x.name_of_sales_officer);
                              Cookies.set("userMobile", x.so_mobile_no, {
                                path: "/",
                              });
                              Cookies.set(
                                "SortString",
                                `SO('${x.name_of_sales_officer}')`,
                                {
                                  path: "/",
                                }
                              );
                              window.location.reload();
                            }}
                            style={{
                              backgroundColor:
                                Cookies.get("userMobile") === x.so_mobile_no
                                  ? "#573a87"
                                  : "transparent",
                              color: "black",
                            }}
                          >
                            <Navbar.Text style={{ color: "black" }}>
                              {x.name_of_sales_officer.toUpperCase()}
                            </Navbar.Text>
                          </NavDropdown.Item>
                        ))
                      : null}
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end">
                <LinkContainer
                  to="/Upload"
                  style={{
                    marginRight: "2%",
                  }}
                >
                  <Nav.Link className="ml-auto">
                    <div
                      className="d-flex "
                      style={{
                        alignItems: "center",
                        marginRight: "20%",
                        width: "100%",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "1rem",
                          padding: 0,
                          margin: 0,
                          textAlign: "end",
                          marginRight: 5,
                          color: "white",
                        }}
                      >
                        {" "}
                        Upload New Customers
                      </h2>
                      <FontAwesomeIcon
                        icon={faFileUpload}
                        size="2x"
                        style={{
                          color: "white",
                          cursor: "pointer",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                    </div>
                  </Nav.Link>
                </LinkContainer>
                <NavDropdown
                  className="custom-dropdown"
                  title={
                    "Logged in as " +
                    (Cookies.get("userName") ||
                      sessionStorage.getItem("userName")) +
                    " " +
                    (Cookies.get("userRole") || Cookies.get("userRole"))
                  }
                  id="customer-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      setIsLoggedIn(false);
                      Cookies.remove("loggedin", { path: "/" });
                      Cookies.remove("userRole", { path: "/" });
                      Cookies.remove("userMobile", { path: "/" });
                      Cookies.remove("userMobileTM", { path: "/" });
                      Cookies.remove("Username", { path: "/" });
                      Cookies.remove("password", { path: "/" });
                      sessionStorage.clear("loggedin");
                      sessionStorage.clear();
                      navigate("/");
                    }}
                  >
                    {" "}
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Navbar.Collapse>
            </Navbar>
          )}

          <Routes>
            {Cookies.get("userRole") === "Distributor" ? (
              <>
                <Route path="/dashboard/" element={<AdminDashboard />} />
                <Route path="/dashboard/" element={<AdminDashboard />} />
                <Route path="/Payments" element={<Payments />} />
                <Route path="/Customer" element={<Customer />} />
                <Route path="/Distributor" element={<Distributor />} />
                <Route
                  path="/DistributorwiseOverdue"
                  element={<DistributorwiseOverdue />}
                />
                <Route
                  path="/DistributorwiseOverdueEMIs"
                  element={<DistributorwiseOverdueEMIs />}
                />
                <Route
                  path="/CustomersOverdue"
                  element={<CustomersOverdue />}
                />
                <Route path="/EMIsOverdue" element={<EMIsOverdue />} />
                <Route path="/Charts" element={<Charts />} />
                <Route path="/Upload" element={<Upload />} />
                <Route path="/SalesOfficer" element={<SalesOfficer />} />

                <Route path="/DistributorData" element={<DistributorData />} />
              </>
            ) : (
              <>
                <Route path="/dashboard/" element={<AdminDashboard />} />
                <Route path="/Payments" element={<Payments />} />
                <Route path="/Customer" element={<Customer />} />
                <Route path="/Distributor" element={<Distributor />} />
                <Route
                  path="/DistributorwiseOverdue"
                  element={<DistributorwiseOverdue />}
                />
                <Route
                  path="/DistributorwiseOverdueEMIs"
                  element={<DistributorwiseOverdueEMIs />}
                />
                <Route
                  path="/CustomersOverdue"
                  element={<CustomersOverdue />}
                />
                <Route path="/EMIsOverdue" element={<EMIsOverdue />} />
                <Route path="/Charts" element={<Charts />} />
                <Route path="/Upload" element={<Upload />} />
                <Route path="/SalesOfficer" element={<SalesOfficer />} />
                <Route
                  path="/TerritoryManager"
                  element={<TerritoryManager />}
                />
                <Route path="/DistributorData" element={<DistributorData />} />
              </>
            )}
          </Routes>
        </>
      ) : location.pathname === "/" ? null : (
        <Loggedout />
      )}

      {/* <Payments /> */}
    </LoginContext.Provider>
  );
}

export default App;

const getCookie = (cookieName) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + "=")) {
      return cookie.substring(cookieName.length + 1); //Return Cookie value
    }
  }
  return null; //If Cookie not found
};
