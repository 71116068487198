import { useNavigate } from "react-router-dom";
import React from "react";
import Cookies from "js-cookie";

import "./Loggedout.scss";
function Loggedout() {
  const navigate = useNavigate();
  return (
    <div className="logged-out-container">
      <h1>You have been logged out</h1>
      <p>Please Log in again to continue using our services.</p>
      <button
        onClick={() => {
          // Clear all cookies
          Cookies.remove("loggedin", { path: "/" });
          Cookies.remove("userRole", { path: "/" });
          Cookies.remove("userMobile", { path: "/" });
          Cookies.remove("Username", { path: "/" });
          Cookies.remove("password", { path: "/" });
          sessionStorage.clear("loggedin");
          navigate("/");
        }}
      >
        {" "}
        Login Again
      </button>
      {/* Add your login form or button here */}
    </div>
  );
}

export default Loggedout;
