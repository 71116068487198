import React, { useEffect, useState } from 'react'
import user_icon from '../assets/user_icon.svg';
import search_icon from '../assets/search_icon.svg';
import download_icon from '../assets/download_icon.svg';
import refresh_icon from '../assets/refresh_icon.svg';
import three_dots from '../assets/three_dots.svg';
import Container from 'react-bootstrap/Container';
import { Button, Pagination, Table } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { default as ReactSelect } from "react-select";
import 'react-calendar/dist/Calendar.css';
import { components } from "react-select";

function TableStatus({ status, text }) {
    const color = text === 'Rejected' ? '#EB5757' : text === 'Pending' ? '#F2994A' : '#12A474';
    const bgcolor = text === 'Rejected' ? '#FFDBDB' : text === 'Pending' ? '#FFF3DB' : '#BBF3E0';
    return (
        <span style={{ backgroundColor: bgcolor, color: color, fontSize: 12, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 8 }}>{text}</span>
    )
}

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const distApprovalList =
    [
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' },
    ];
const deliveryStatusList =
    [
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' },
    ];

const postedAtList =
    [
        { value: 'Today', label: 'Today' },
        { value: 'This week', label: 'This week' },
        { value: 'This month', label: 'This month' },
        { value: 'This year', label: 'This year' },
        { value: 'Set up', label: 'Set up' },
    ];


const loanDisbursmentList =
    [
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' },
    ];
const nameList =
    [
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'rejected', label: 'Rejected' },
    ];

export default function Payments() {
    const [startDate, onStartDateChange] = useState(new Date());
    const [endDate, onEndDateChange] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState(null);
    const [postedAt, setPostedAt] = useState(null);
    const [loanDisbursment, setLoanDisbursment] = useState(null);
    const [deliveryStatus, setDeliveryStatus] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    useEffect(() => {
        console.log(selectedOption);
    }, [selectedOption])

    return (
        <Container>
            <div className="d-flex justify-content-between" style={{ height: 150 }}>
                <h1 className='align-self-end m-4'>Payments</h1>
                <img src={user_icon} alt="" className='m-4' height={44} width={44} />
            </div>
            <div className='d-flex flex-wrap'>
                <div className="input-group m-1" style={{ maxWidth: 600 }}>
                    <img src={search_icon} alt="" height={44} width={44} className="input-group-text bg-light  border light" id="basic-addon1" />
                    <input type="text" className="form-control border light" placeholder="Search by" aria-label="search" aria-describedby="basic-addon1" />
                </div>

                <div style={{ minWidth: 170 }} className='m-1' >
                    <ReactSelect
                        styles={{
                            control: (baseStyle, state) => ({
                                ...baseStyle, borderColor: '#EBEBEB', "&:hover": {
                                    borderColor: "#573A87"
                                }, height: 42,
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#573A87' : 'inherit'
                            })
                        }}
                        placeholder={"Name"}
                        options={nameList}
                        hideSelectedOptions={false}
                        isClearable={true}
                        components={{
                            Option
                        }}
                        onChange={(v) => { setSelectedName(v) }}
                        value={selectedName}
                    />
                </div>
                <Button variant="light border m-1">Search</Button>
                <img src={download_icon} alt="" height={44} width={44} className="input-group-text bg-light border light m-1" id="basic-addon1" />
                <img src={refresh_icon} alt="" height={44} width={44} className="input-group-text bg-light border light m-1" id="basic-addon1" />
            </div>
            <div className='d-flex flex-wrap'>
                <div style={{ minWidth: 300 }} className='m-2' >
                    <ReactSelect
                        styles={{
                            control: (baseStyle, state) => ({
                                ...baseStyle, borderColor: '#EBEBEB', "&:hover": {
                                    borderColor: "#573A87"
                                }, height: 42,
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#573A87' : 'inherit'
                            })
                        }}
                        placeholder={"Posted at"}
                        options={postedAtList}
                        hideSelectedOptions={false}
                        isClearable={true}
                        components={{
                            Option
                        }}
                        onChange={(v) => { setPostedAt(v) }}
                        value={postedAt}
                    />
                </div>
                <div style={{ minWidth: 380 }} className='m-2' >
                    <ReactSelect
                        styles={{
                            control: (baseStyle, state) => ({
                                ...baseStyle, borderColor: '#EBEBEB', "&:hover": {
                                    borderColor: "#573A87"
                                }, height: 42,
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#573A87' : 'inherit'
                            })
                        }}
                        placeholder={"Distributor Approval"}
                        options={distApprovalList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                            Option
                        }}
                        onChange={(v) => { setSelectedOption(v) }}
                        value={selectedOption}
                    />
                </div>
                <div style={{ minWidth: 275 }} className='m-2' >
                    <ReactSelect
                        styles={{
                            control: (baseStyle, state) => ({
                                ...baseStyle, borderColor: '#EBEBEB', "&:hover": {
                                    borderColor: "#573A87"
                                }, height: 42,
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#573A87' : 'inherit'
                            })
                        }}
                        placeholder={"Loan Disbursement"}
                        options={loanDisbursmentList}
                        hideSelectedOptions={false}
                        isClearable={true}
                        components={{
                            Option
                        }}
                        onChange={(v) => { setLoanDisbursment(v) }}
                        value={loanDisbursment}
                    />
                </div>
                <div style={{ minWidth: 275 }} className='m-2' >
                    <ReactSelect
                        styles={{
                            control: (baseStyle, state) => ({
                                ...baseStyle, borderColor: '#EBEBEB', "&:hover": {
                                    borderColor: "#573A87"
                                }, height: 42,
                            }),
                            option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isSelected ? '#573A87' : 'inherit'
                            })
                        }}
                        placeholder={"Delivery Status"}
                        options={deliveryStatusList}
                        hideSelectedOptions={false}
                        isClearable={true}
                        components={{
                            Option
                        }}
                        onChange={(v) => { setDeliveryStatus(v) }}
                        value={deliveryStatus}
                    />
                </div>
            </div>
            {postedAt && postedAt.value === "Set up" ?
                <div className='row col-lg-8'>
                    <div className='row p-5'>
                        <Calendar onChange={onStartDateChange} value={startDate} />
                        <Calendar onChange={onEndDateChange} value={endDate} />
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: '70%' }}>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <p>Start Date: </p>
                                <p style={{ padding: 8, borderRadius: 20, border: '2px solid #573A87', marginLeft: 8 }}>{startDate.getMonth() - 1 + '.' + startDate.getDate() + '.' + startDate.getFullYear()}</p>
                            </div>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <p>End Date: </p>
                                <p style={{ padding: 8, borderRadius: 20, border: '2px solid #573A87', marginLeft: 8 }}>{endDate.getMonth() - 1 + '.' + endDate.getDate() + '.' + endDate.getFullYear()}</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            <Button variant="light border m-2" style={{ borderRadius: 20, paddingLeft: 20, paddingRight: 20 }} onClick={() => { setPostedAt(null) }}>Cancel</Button>
                            <Button variant="primary border m-2" style={{ borderRadius: 20, paddingLeft: 20, paddingRight: 20 }}>Apply</Button>
                        </div>
                    </div>
                </div>
                : null}
            <Table className='mt-3' hover style={{ textAlign: 'center' }} responsive>
                <thead>
                    <tr style={{ color: '#809FB8', height: 50 }}>
                        <td>#</td>
                        <td>LPG Number</td>
                        <td>Name</td>
                        <td>Mobile</td>
                        <td>Distributor</td>
                        <td>Loan Status</td>
                        <td>Loan Started at</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ color: '#06152B', fontWeight: '400', }}>1234567</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>102117142</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Kamla Devi</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>9999999999</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Michto Gas</td>
                        <td><TableStatus text="Pending" /></td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>July 2022</td>
                        <td><img src={three_dots} alt="" height={20} width={20} className="dark border p-1 rounded" id="basic-addon1" /></td>
                    </tr>
                    <tr>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>1234567</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>102117142</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Kamla Devi</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>9999999999</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Michto Gas</td>
                        <td><TableStatus text="Approved" /></td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>July 2022</td>
                        <td><img src={three_dots} alt="" height={20} width={20} className="dark border p-1 rounded" id="basic-addon1" /></td>
                    </tr>
                    <tr>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>1234567</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>102117142</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Kamla Devi</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>9999999999</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Michto Gas</td>
                        <td><TableStatus text="Rejected" /></td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>July 2022</td>
                        <td><img src={three_dots} alt="" height={20} width={20} className="dark border p-1 rounded" id="basic-addon1" /></td>
                    </tr>
                    <tr>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>1234567</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>102117142</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Kamla Devi</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>9999999999</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Michto Gas</td>
                        <td><TableStatus text="Pending" /></td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>July 2022</td>
                        <td><img src={three_dots} alt="" height={20} width={20} className="dark border p-1 rounded" id="basic-addon1" /></td>
                    </tr>
                    <tr>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>1234567</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>102117142</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Kamla Devi</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>9999999999</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Michto Gas</td>
                        <td><TableStatus text="Rejected" /></td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>July 2022</td>
                        <td><img src={three_dots} alt="" height={20} width={20} className="dark border p-1 rounded" id="basic-addon1" /></td>
                    </tr>
                    <tr>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>1234567</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>102117142</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Kamla Devi</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>9999999999</td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>Michto Gas</td>
                        <td><TableStatus text="Rejected" /></td>
                        <td style={{ color: '#06152B', fontWeight: '400' }}>July 2022</td>
                        <td><img src={three_dots} alt="" height={20} width={20} className="dark border p-1 rounded" id="basic-addon1" /></td>
                    </tr>
                </tbody>
            </Table>
            <div>
                <Pagination style={{ float: 'right' }}>
                    <Pagination.First />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Item>{6}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Last />
                </Pagination>
            </div>
        </Container>
    )
}
