import React, { useContext } from "react";

import GreattrLogo from "./assets/images/greattr_name_logo.png";
import "./Signin.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "./LTC";
import Cookies from "js-cookie";
import ChangePasswordModal from "./ChangePasswordModal";

import axios from "axios";
import { GES, GDSRecords, convertToHash } from "../ENDE";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;
function Signin() {
  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);

  const [Username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [selectedTabKey, setSelectedTabKey] = useState("SO/TM");

  const handleTabSelect = (eventKey) => {
    setSelectedTabKey(eventKey);
  };
  // Function to retrieve and log cookie values
  const logCookieValues = () => {
    const loggedIn = Cookies.get("loggedin");
    const userRole = Cookies.get("userRole");
    const userMobile = Cookies.get("userMobile");

    console.log("Logged In:", loggedIn);
    console.log("User Role: man", userRole);
    console.log("User Mobile: man", userMobile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Username:", Username);
    // console.log("Password:", password);

    if (Username === "bpclGreattr0410" && password === "jaigurudev") {
      setIsLoggedIn(true);
      // Store the isLoggedIn value in a cookie
      document.cookie = "loggedin=true; path=/";
      sessionStorage.setItem("loggedin", true);
      navigate("/dashboard");
    }
    if (Username === "bpclGreattrAB" && password === "JBB281099") {
      // Store the isLoggedIn value in a cookie
      // document.cookie = "loggedin=true; path=/";
      setIsLoggedIn(true);
      // Cookies.set("userRole", 'UP head', { path: "/" });
      // Cookies.set("userMobile", "Ajay Bhagat", { path: "/" });
      sessionStorage.setItem("userRole", "BPCL head");
      sessionStorage.setItem("userName", "Ajay Bhagat");
      Cookies.set("userRole", "BPCL head", { path: "/" });
      Cookies.set("userName", "Ajay Bhagat", { path: "/" });
      logCookieValues();

      sessionStorage.setItem("loggedin", true);

      const response2 = await axios.post(
        `${process.env.REACT_APP_API_LINK}/SO_details`,
        {
          // Replace "YOUR_API_ENDPOINT_HERE" with the actual endpoint where you want to send the login details
          Access: "Full",
        }
      );
      if (response2.status === 200) {
        // Authentication successful, perform the necessary actions
        console.log(response2.data);

        var TMData = GDSRecords(response2.data.TMData, apiDE);
        var SOData = GDSRecords(response2.data.SOData, apiDE);

        console.log("------>>>>>>SO Data", SOData);
        console.log("so data ");
        console.log("------>>>>>>TM Data", TMData);

        sessionStorage.setItem("SOData", JSON.stringify(SOData));
        sessionStorage.setItem("TMData", JSON.stringify(TMData));
        navigate("/dashboard");
      } else {
        alert("Error logging in ");
        // Handle authentication failure
        console.error("Authentication failed");
      }
    } else if (Username === "bpclGreattrDS" && password === "JSR150897") {
      // Store the isLoggedIn value in a cookie
      // document.cookie = "loggedin=true; path=/";
      setIsLoggedIn(true);
      // Cookies.set("userRole", 'UP head', { path: "/" });
      // Cookies.set("userMobile", "Ajay Bhagat", { path: "/" });
      sessionStorage.setItem("userRole", "UP head");
      sessionStorage.setItem("userName", "Dhiraj Sahu");
      Cookies.set("userRole", "UP head", { path: "/" });
      Cookies.set("userName", "Dhiraj Sahu", { path: "/" });
      logCookieValues();

      sessionStorage.setItem("loggedin", true);

      const response2 = await axios.post(
        `${process.env.REACT_APP_API_LINK}/SO_details`,
        {
          // Replace "YOUR_API_ENDPOINT_HERE" with the actual endpoint where you want to send the login details
          Access: "Full",
        }
      );
      if (response2.status === 200) {
        // Authentication successful, perform the necessary actions
        console.log(response2.data);

        var TMData = GDSRecords(response2.data.TMData, apiDE);
        var SOData = GDSRecords(response2.data.SOData, apiDE);

        console.log("------>>>>>>SO Data", SOData);
        console.log("so data ");
        console.log("------>>>>>>TM Data", TMData);

        sessionStorage.setItem("SOData", JSON.stringify(SOData));
        sessionStorage.setItem("TMData", JSON.stringify(TMData));
        navigate("/dashboard");
      } else {
        alert("Error logging in ");
        // Handle authentication failure
        console.error("Authentication failed");
      }
    } else if (Username === "admin" && password === "8779096439") {
      sessionStorage.setItem("Username", Username);
      sessionStorage.setItem("loggedin", true);
      setIsLoggedIn(true);
      sessionStorage.setItem("password", password);
      // Store the isLoggedIn value in a cookie
      document.cookie = "loggedin=true; path=/";
      // Store user data in cookies
      document.cookie = `Username=${Username}; path=/`;
      Cookies.set("userRole", "Administrator", { path: "/" });
      Cookies.set("userName", Username, { path: "/" });
      document.cookie = `password=${password}; path=/`;

      navigate("/Dashboard");
    } else {
      // Handle the case where none of the previous conditions match
      if (selectedTabKey === "SO/TM") {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_LINK}/dashboardSignIn`,
            {
              // Replace "YOUR_API_ENDPOINT_HERE" with the actual endpoint where you want to send the login details
              username: Username,
              password: password,
            }
          );
          console.log("response", response);
          console.log(response.status);
          console.log(response.status === 200);
          if (
            response.status === 200 &&
            response.data.message === "Login successful"
          ) {
            // Authentication successful, perform the necessary actions
            setIsLoggedIn(true);
            Cookies.set("loggedin", true, { path: "/" });
            Cookies.set("userRole", response.data.role, { path: "/" });
            Cookies.set("userName", response.data.name, { path: "/" });
            Cookies.set("userMobile", response.data.mobile, { path: "/" });
            Cookies.set("userMobileTM", response.data.mobile, { path: "/" });

            sessionStorage.setItem("userRole", response.data.role);
            sessionStorage.setItem("userName", response.data.name);

            // Retrieve the "userData" cookie
            const userDataCookie = Cookies.get("userRole");
            logCookieValues();
            sessionStorage.setItem("loggedin", true);
            console.log("========================");
            const response2 = await axios.post(
              `${process.env.REACT_APP_API_LINK}/SO_details`,
              {
                // Replace "YOUR_API_ENDPOINT_HERE" with the actual endpoint where you want to send the login details
                Access: "Restricted",
                entity_mobile: response.data.mobile,
              }
            );
            if (response2.status === 200) {
              // Authentication successful, perform the necessary actions
              console.log(response2.data);
              var SOData = GDSRecords(response2.data.SOData, apiDE);
              console.log(SOData);
              sessionStorage.setItem("SOData", JSON.stringify(SOData));
              navigate("/dashboard");
            }
          } else {
            alert("Error logging in ");
            // Handle authentication failure
            console.error("Authentication failed");
          }
        } catch (error) {
          alert(error);
          if (error.response.status === 401) {
            //Hit distributor Api
          }
        }
      } else {
        // DIST LOGIN
        try {
          const datax = GES(
            {
              distid: Username,
              password: convertToHash(password),
            },
            apiEN
          );
          await axios({
            url: `${process.env.REACT_APP_API_LINK}/distloginEncryptedForDashboard`,
            method: "POST",
            responseType: "json",
            data: datax,
          }).then((response) => {
            if (response.data.status === "authenticated") {
              console.log("response", response.data);
              setIsLoggedIn(true);
              Cookies.set("loggedin", true, { path: "/" });
              Cookies.set("userRole", "Distributor", { path: "/" });
              Cookies.set("userName", response.data.name, { path: "/" });
              Cookies.set("userCC", Username, { path: "/" });
              navigate("/dashboard");
            } else {
              alert("Error logging in ");
              // Handle authentication failure
              console.error("Authentication failed");
            }
          });
        } catch (error) {
          alert(error);
          if (error.response.status === 401) {
            //Hit distributor Api
          }
        }
      }
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <img src={GreattrLogo} alt="GreattrLogo" width={"50%"} />
        {/* <label htmlFor="Username">Username:</label> */}
        <br />
        <Tabs
          defaultActiveKey="Distributor"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={handleTabSelect}
        >
          <Tab eventKey="Distributor" title="Distributor">
            Distributor
          </Tab>
          <Tab eventKey="SO/TM" title="SO/TM">
            SO/TM
          </Tab>
        </Tabs>
        <br />
        <input
          type="Username"
          id="Username"
          className="inputx"
          placeholder="Username"
          value={Username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {/* <label htmlFor="password">Password:</label> */}
        <input
          type="password"
          className="inputx"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {selectedTabKey === "SO/TM" && <ChangePasswordModal />}
        <button type="submit" className="fancy-button">
          Submit
        </button>
      </form>
    </div>
  );
}

export default Signin;
