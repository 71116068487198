import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import "./Upload.scss";
import ExcelDataTable from "./ExcelDataTable";
import { useTable, useSortBy } from "react-table";
import { GDSRecords, GESRecords } from "../ENDE";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [headersMatch, setHeadersMatch] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [errorValues, setErrorValues] = useState([]);
  const [duplicate, setDuplicate] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [showMoreErrors, setShowMoreErrors] = useState(false); //keep track of whether to show more errors or not.
  const [excelData, setExcelData] = useState([]); // Store Excel data
  const [columns, setColumns] = useState([]); // Use state to manage columns
  const [excelFileData, setExcelFileData] = useState();
  const [newData, setNewData] = useState();
  const [oldData, setOldData] = useState();
  const [DuplicateMobile, setDuplicateMobile] = useState([])
  const [oldKycData, setOldKycData] = useState();
  const [snewData, setsNewData] = useState();
  const [soldData, setsOldData] = useState();
  const [soldKycData, setsOldKycData] = useState();

  const itemsPerPage = 10;
  const [headersInFilex, setheadersInFilex] = useState(null);
  // Generate columns based on the data
  const navigate = useNavigate();

  const newColumnsRef = useRef([]);
  const oldColumnsRef = useRef([]);
  const oldKycColumnsRef = useRef([]);

  const expectedHeaders = [
    "DISTRIBUTOR ID",
    "DISTRIBUTOR NAME",
    "CUSTOMER LPG ID",
    "CUSTOMER NAME_AS_AADHAAR",
    "CUSTOMER MOBILE",
    "GENDER",
    "CUSTOMER DOB",
    "ADDRESS_LINE1",
    "ADDRESS_LINE2",
    "STATE",
    "CITY",
    "PINCODE",
    "DISTRICT",
  ];
  function convertDateFormat(dateString) {
    const [day, month, year] = dateString.split('-');

    // Determine the century based on the year (e.g., assume years 00-49 are in the 2000s)
    const currentYear = new Date().getFullYear();
    const currentCentury = Math.floor(currentYear / 100) * 100;
    const parsedYear = parseInt(year);

    // Determine the correct century for the year
    const century = parsedYear < currentYear % 100 ? currentCentury : currentCentury - 100;

    // Reconstruct the date string in DD-MM-YYYY format
    const formattedDate = `${String(day).padStart(2, '0')}`;

    return formattedDate;
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const headersInFile = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,

      })[0];
      const dataRows = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        range: 1,
        raw: false,
      });
      setheadersInFilex(headersInFile);

      const jsonData = [];

      dataRows.forEach((row) => {
        const rowData = {};
        headersInFile.forEach((header, columnIndex) => {
          rowData[header] = row[columnIndex];
        });
        jsonData.push(rowData);
      });


      setExcelFileData(jsonData);
      setheadersInFilex(headersInFile);
      const mobileColumnIndex = expectedHeaders.indexOf("CUSTOMER MOBILE");
      const lpgIdColumnIndex = expectedHeaders.indexOf("CUSTOMER LPG ID");
      const distLpgIdColumnIndex = expectedHeaders.indexOf("DISTRIBUTOR ID");
      const pincodeColumnIndex = expectedHeaders.indexOf("PINCODE");
      const cityColumnIndex = expectedHeaders.indexOf("CITY");
      const stateColumnIndex = expectedHeaders.indexOf("STATE");
      const DistrictColumnIndex = expectedHeaders.indexOf("DISTRICT");
      const dobColumnIndex = expectedHeaders.indexOf("CUSTOMER DOB");
      const add1ColumnIndex = expectedHeaders.indexOf("ADDRESS_LINE1");
      const add2ColumnIndex = expectedHeaders.indexOf("ADDRESS_LINE2");
      const statusColumnIndex = expectedHeaders.indexOf("status");
      const monthlyincomeColumnIndex = expectedHeaders.indexOf("monthlyincome");
      const employmentColumnIndex = expectedHeaders.indexOf("employment");
      const nameColumnIndex = expectedHeaders.indexOf("CUSTOMER NAME_AS_AADHAAR");
      const genderColumnIndex = expectedHeaders.indexOf("GENDER");
      const lpgdistnameColumnIndex = expectedHeaders.indexOf("DISTRIBUTOR NAME");
      const newErrorValues = [];
      console.log("datarows");
      console.log(jsonData);
      console.log(JSON.stringify(jsonData));

      // Store duplicate values
      var duplicates = [];

      for (var i = 0; i < jsonData.length; i++) {
        var item = jsonData[i];

        for (var j = i + 1; j < jsonData.length; j++) {
          var item2 = jsonData[j];

          if (item2["CUSTOMER MOBILE"] === item["CUSTOMER MOBILE"] || item2["CUSTOMER LPG ID"] === item["CUSTOMER LPG ID"]) {
            if (!duplicates.includes(item)) {
              duplicates.push(item);
            }
            if (!duplicates.includes(item2)) {
              duplicates.push(item2);
            }
          }
        }
      }

      dataRows.forEach((row, rowIndex) => {
        const mobileValue = row[mobileColumnIndex];
        const lpgIdValue = row[lpgIdColumnIndex];
        const distLpgIdValue = row[distLpgIdColumnIndex];
        const pincodeIDValue = row[pincodeColumnIndex];
        // Reconstruct the date string in DD-MM-YYYY format
        // const dobValue = convertDateFormat(row[dobColumnIndex])
        const dobValue = (row[dobColumnIndex])
        const add1Value = row[add1ColumnIndex];
        const add2Value = row[add2ColumnIndex];
        const cityValue = row[cityColumnIndex];
        const districtValue = row[DistrictColumnIndex];
        const stateValue = row[stateColumnIndex];
        const nameValue = row[nameColumnIndex];
        const distnameValue = row[lpgdistnameColumnIndex];

        function containsUnwantedChars(value) {
          const specialSQLCharsPattern = /;|--|'|,|\/\*|\*\/|DROP|DELETE|UPDATE|INSERT|SELECT|EXEC/i;
          console.log(value);
          console.log(specialSQLCharsPattern.test(value));
          // Check if the value matches the pattern
          return specialSQLCharsPattern.test(value);

        }


        console.log('mob header', mobileColumnIndex);
        expectedHeaders.forEach((header) => { //UNIVERSAL ERROR CHECK
          console.log(header);
          console.log(expectedHeaders.indexOf(header));

          if (containsUnwantedChars(row[expectedHeaders.indexOf(header)])) {
            newErrorValues.push({
              row: rowIndex + 2,
              column: header,
              value: row[expectedHeaders.indexOf(header)],
              message: "Error: Your input contains prohibited characters"

            });
          }
        });


        if (!/^\d{10}$/.test(mobileValue)) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "CUSTOMER MOBILE",
            value: mobileValue,
            message: "Mobile number must be 10 digit"

          });
        }
        // Assuming dobValue is the DOB value you want to validate
        console.log('dob');
        console.log(dobValue);
        console.log('dob');
        if (!/^\d{2}-\d{2}-\d{4}$/.test(dobValue)) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "CUSTOMER DOB",
            value: dobValue,
            message: "Date should be in DD-MM-YYYY format"
          });
        }

        if (!/^\d{17}$/.test(lpgIdValue)) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "CUSTOMER LPG ID",
            value: lpgIdValue,
            message: "Customer LPG ID must be 17 digit"

          });
        }

        if (!/^\d{6}$/.test(distLpgIdValue)) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "DISTRIBUTOR ID",
            value: distLpgIdValue,
            message: "Distributor CC Code must be 6 digit"

          });
        }

        if (!/^\d{6}$/.test(pincodeIDValue)) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "PINCODE",
            value: pincodeIDValue,
            message: "Pincode must be 6 digit"

          });
        }



        if (add1Value?.length < 3) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "ADDRESS_LINE1",
            value: add1Value,
            message: "Address Line 1 must have at least 3 characters.",
          });
        }

        if (add2Value?.length < 3) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "ADDRESS_LINE2",
            value: add2Value,
            message: "Address Line 2 must have at least 3 characters.",
          });
        }

        if (cityValue?.length < 3) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "CITY",
            value: cityValue,
            message: "City must have at least 3 characters.",
          });
        }

        if (districtValue?.length < 3) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "DISTRICT",
            value: districtValue,
            message: "District must have at least 3 characters.",
          });
        }

        if (stateValue?.length < 1) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "STATE",
            value: stateValue,
            message: "state must have at least 3 characters.",
          });
        }

        if (nameValue?.length < 3) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "CUSTOMER NAME_AS_AADHAAR",
            value: nameValue,
            message: "Name as per pan must have at least 3 characters.",
          });
        }

        if (distnameValue?.length < 3) {
          newErrorValues.push({
            row: rowIndex + 2,
            column: "DISTRIBUTOR NAME",
            value: distnameValue,
            message: "Dist name must have at least 3 characters.",
          });
        }
        // Check for duplicate values
      });
      console.log("00000000000000000000000000000000");
      console.log(JSON.stringify(headersInFile));
      console.log(JSON.stringify(expectedHeaders));
      const headersMatch =
        JSON.stringify(headersInFile) === JSON.stringify(expectedHeaders);
      console.log(headersMatch);
      setHeadersMatch(headersMatch);
      // setValidationErrors(errors);

      console.log("Data Rows:", dataRows);
      console.log("Columns:", headersInFile);

      // Set columns using state
      setColumns(
        headersInFile.map((header) => ({
          Header: header,
          accessor: header,
        }))
      );

      // Map dataRows to excelData
      const mappedData = dataRows.map((row) => {
        const rowData = {};
        headersInFile.forEach((header, columnIndex) => {
          rowData[header] = row[columnIndex];
        });
        return rowData;
      });
      console.log("mappedData", mappedData);
      // Set excelData directly
      setExcelData(mappedData);
      setValidationErrors([]);
      setErrorValues(newErrorValues);
      console.log("duplicateData");
      console.log("duplicateData", duplicates);

      setDuplicate(duplicates);
    };
    if (file) {
      reader.readAsArrayBuffer(file);
    }
  };
  const [kycDoneData, setkycDoneData] = useState([]);
  const [kycDoneDataInvalid, setkycDoneDataInvalid] = useState([]);
  const [existingData, setexistingData] = useState([]);
  const [newdata, setnewdata] = useState([]);

  // Send Old and New data for Upload
  const downloadExcelwithData = async () => {

    try {
      // Define your data objects (existingData, newData, etc.) here
      const data = {
        existingData: existingData,
        newData: newdata,
        existingDataKyc: kycDoneData,
        existingDataKycInvalid: kycDoneDataInvalid,
      };

      // Send a POST request to the server
      const response = await axios.post(`${process.env.REACT_APP_API_LINK}/generate-excel-for-verified-data`, data, {
        responseType: 'blob', // Set the response type to 'blob' to handle binary data
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a URL for the Blob
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a temporary anchor element
      const tempLink = document.createElement('a');
      tempLink.href = blobUrl;
      tempLink.setAttribute('download', 'data.xlsx');

      // Trigger a click event on the anchor element to initiate the download
      tempLink.click();

      // Clean up the URL and temporary element
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error generating Excel:', error);
    }
  };
  const handleUpload2 = () => {
    console.log("================================");

    console.log("existingData", oldData);
    console.log("newdata", newData);
    console.log({ ...newdata, ...existingData });
    console.log("================================");
    if (newData || oldData || kycDoneData) {
      var datax = [...Object.values(newData), ...Object.values(oldData)];
      var alldatax = [...Object.values(newData), ...Object.values(oldData), ...Object.values(kycDoneData)];
      console.log(datax);
      axios
        .post(
          `${process.env.REACT_APP_API_LINK}/uploadExcelFile`,
          {
            data: GESRecords(datax, apiEN),
            allmobiledata: GESRecords(alldatax, apiEN),
            UploadedBy: Cookies.get("userMobile")
          }, // Send the formData object
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            alert("File Upload Completed.");
            navigate("/dashboard");

          } else {
            alert("File upload failed.");
          }
        })
        .catch((error) => {
          alert("File upload failed.");
          console.error("Error:", error);
        });
    }
  };
  function convertToOriginalColumnNames(dataWithUpdatedNames) {
    // Define a mapping of updated column names to original column names (vice versa)
    const columnMapping = {
      "lpg_dist_id": "DISTRIBUTOR ID",
      "lpg_dist_name": "DISTRIBUTOR NAME",
      "lpg_id": "CUSTOMER LPG ID",
      "name_as_pan": "CUSTOMER NAME_AS_AADHAAR",
      "mobile": "CUSTOMER MOBILE",
      "gender": "GENDER",
      "dob": "CUSTOMER DOB",
      "address_line1": "ADDRESS_LINE1",
      "address_line2": "ADDRESS_LINE2",
      "state": "STATE",
      "city": "CITY",
      "pincode": "PINCODE",
      "district": "DISTRICT",
    };

    // Create a new array with original column names
    const dataWithOriginalColumnNames = dataWithUpdatedNames.map((rowData) => {
      const updatedRowData = {};

      // Iterate through the updated column names and copy data to original column names
      for (const updatedColumnName in rowData) {
        if (columnMapping.hasOwnProperty(updatedColumnName)) {
          const originalColumnName = columnMapping[updatedColumnName];
          updatedRowData[originalColumnName] = rowData[updatedColumnName];
        } else {
          // If the column name is not in the mapping, keep it as is
          updatedRowData[updatedColumnName] = rowData[updatedColumnName];
        }
      }

      return updatedRowData;
    });

    return dataWithOriginalColumnNames;
  }
  // Use useEffect to perform actions after state updates
  useEffect(() => {
    // Move the variable declarations inside the useEffect

    if (newData !== null) {
      if (newData) {
        const newDataArray = convertToOriginalColumnNames(Object.values(newData));
        console.log(newData);
        newColumnsRef.current = extractColumnHeaders(newDataArray);
        console.log(newColumnsRef.current);
        setsNewData(newDataArray);
      } else {
      }
    }
    if (oldData !== null) {
      if (oldData) {
        const oldDataArray = convertToOriginalColumnNames(Object.values(oldData));
        oldColumnsRef.current = extractColumnHeaders(oldDataArray);
        setsOldData(oldDataArray);
      }
    }
    if (oldKycData !== null) {
      if (oldKycData) {
        const oldDataKycArray = convertToOriginalColumnNames(Object.values(oldKycData));
        oldKycColumnsRef.current = extractColumnHeaders(oldDataKycArray);
        setsOldKycData(oldDataKycArray);
      }
    }
  }, [newData, oldData, oldKycData]);
  const handleUpload = () => {
    if (headersMatch && selectedFile && errorValues.length === 0) {
      const formData = new FormData();
      formData.append("excelFile", selectedFile);
      const jsonData = XLSX.utils.sheet_to_json(selectedFile);
      formData.append("jsonData", JSON.stringify(jsonData));

      console.log("Json Data", excelData);

      // Define a mapping of old column names to new column names
      const columnMapping = {
        "DISTRIBUTOR ID": "lpg_dist_id",
        "DISTRIBUTOR NAME": "lpg_dist_name",
        "CUSTOMER LPG ID": "lpg_id",
        "CUSTOMER NAME_AS_AADHAAR": "name_as_pan",
        "CUSTOMER MOBILE": "mobile",
        "GENDER": "gender",
        "CUSTOMER DOB": "dob",
        "ADDRESS_LINE1": "address_line1",
        "ADDRESS_LINE2": "address_line2",
        "STATE": "state",
        "CITY": "city",
        "PINCODE": "pincode",
        "DISTRICT": "district",
      };

      // Create a new array with updated column names
      const dataWithChangedColumnNames = excelFileData.map((rowData) => {
        const updatedRowData = {};

        // Iterate through the old column names and copy data to new column names
        for (const oldColumnName in rowData) {
          if (columnMapping.hasOwnProperty(oldColumnName)) {
            const newColumnName = columnMapping[oldColumnName];
            updatedRowData[newColumnName] = rowData[oldColumnName];
          } else {
            // If the column name is not in the mapping, keep it as is
            updatedRowData[oldColumnName] = rowData[oldColumnName];
          }
        }

        return updatedRowData;
      });

      console.log("dataWithOUTChangedColumnNames");
      console.log(excelData);
      console.log("dataWithChangedColumnNames");
      console.log(dataWithChangedColumnNames);
      let encryptedData = GESRecords(dataWithChangedColumnNames, apiEN);

      console.log(encryptedData);
      // Send the form data to the server
      console.log(process.env.REACT_APP_API_LINK);
      axios
        .post(
          `${process.env.REACT_APP_API_LINK}/lpg_detailsDashboardBulkUploadVerification`,
          encryptedData, // Send the formData object
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            alert("File Verification Completed.");
            // let decryptednewData = GDSRecords(response.data.newData, apiDE);
            // let existingData = GDSRecords(response.data.existingData, apiDE);
            // let existingDataKYC = GDSRecords(
            //   response.data.existingDataKyc,
            //   apiDE
            // );
            console.log(
              "Upload response fro api :/lpg_detailsDashboardBulkUploadVerification",
              response.data
            );
            setDuplicateMobile(GDSRecords(response.data.DuplicateMobile, apiDE));
            setNewData(GDSRecords(response.data.newData, apiDE));
            setOldData(GDSRecords(response.data.existingData, apiDE));
            setOldKycData(GDSRecords(response.data.existingDataKyc, apiDE));

            setnewdata(GDSRecords(response.data.newData, apiDE));
            setexistingData(GDSRecords(response.data.existingData, apiDE));
            setkycDoneData(GDSRecords(response.data.existingDataKyc, apiDE));
            setkycDoneDataInvalid(GDSRecords(response.data.existingDataKycInvalid, apiDE));

            console.log("Decrypted New Data:");
            console.log(GDSRecords(response.data.newData, apiDE));
            console.log("Existing Data:");
            console.log(GDSRecords(response.data.existingData, apiDE));
            console.log("Existing Data KYC:");
            console.log(GDSRecords(response.data.existingDataKyc, apiDE));
          } else {
            alert("File upload failed.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      alert(
        "File headers do not match the expected headers or there are validation errors."
      );
    }
  };
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentErrorValues = errorValues.slice(firstItemIndex, lastItemIndex);
  // const currentDuplicateValues = duplicate.slice(firstItemIndex, lastItemIndex);

  const totalPages = Math.ceil(errorValues.length / itemsPerPage);
  // const totalPagesDuplicate = Math.ceil(duplicate.length / itemsPerPage);

  const lastItemIndex1 = currentPage1 * itemsPerPage;
  const firstItemIndex1 = lastItemIndex1 - itemsPerPage;
  const currentErrorValues1 = errorValues.slice(
    firstItemIndex1,
    lastItemIndex1
  );
  const totalPages1 = Math.ceil(errorValues.length / itemsPerPage);

  const lastItemIndex2 = currentPage2 * itemsPerPage;
  const firstItemIndex2 = lastItemIndex2 - itemsPerPage;
  const currentDuplicateValues = duplicate.slice(
    firstItemIndex2,
    lastItemIndex2
  );
  const totalPagesDuplicate = Math.ceil(duplicate.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage1 = () => {
    if (currentPage1 < totalPages1) {
      setCurrentPage1(currentPage1 + 1);
    }
  };

  const handlePrevPage1 = () => {
    if (currentPage1 > 1) {
      setCurrentPage1(currentPage1 - 1);
    }
  };

  const handleNextPage2 = () => {
    if (currentPage2 < totalPagesDuplicate) {
      setCurrentPage2(currentPage2 + 1);
    }
  };

  const handlePrevPage2 = () => {
    if (currentPage2 > 1) {
      setCurrentPage2(currentPage2 - 1);
    }
  };

  const extractColumnHeaders = (data) => {
    if (!data || data.length === 0) {
      return []; // Return an empty array if data is undefined or empty
    }

    // Assuming the first data object contains all the necessary columns
    const firstDataObject = data[0];
    if (!firstDataObject) {
      return []; // Return an empty array if the first data object is undefined
    }

    return Object.keys(firstDataObject).map((key) => ({
      Header: key,
      accessor: key,
    }));
  };

  const sampleFileName = "sample_data.xlsx";
  const sampleFilePath = `${process.env.PUBLIC_URL}/${sampleFileName}`;

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.href = sampleFilePath;
    link.download = "sample.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log("Sample Excel Downloaded.")
  };


  return (
    <div className="upload-container">
      <div className="head">
        <h1>Upload Your Excel File </h1>
      </div>
      <input type="file" accept=".xlsx" onChange={handleFileChange} />
      <button className="upload-button" onClick={handleUpload}>
        Verify
      </button>

      <button className="download-button" onClick={handleDownloadSample}>
        Download Sample Excel
      </button>

      {headersMatch && currentErrorValues1.length === 0 && (
        <div className="success-message">
          Headers and validations match successfully!
        </div>
      )}

      {headersMatch && currentErrorValues1.length > 0 && (
        <div className="error-message">Validations Errors</div>
      )}

      {headersMatch === false && (
        <div
          className="error-message"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          File headers do not match the expected headers.
          <table
            style={{
              alignSelf: "center",
            }}
          >
            <thead>
              <tr>
                <th style={{ color: "black", borderBottomWidth: 2 }}>Input</th>
                <th style={{ color: "black", borderBottomWidth: 2 }}>
                  Expected
                </th>
              </tr>
            </thead>
            <tbody>
              {headersInFilex.map((header, index) => (
                <tr key={index}>
                  <td>{header}</td>
                  <td>{expectedHeaders[index]}</td>
                </tr>
              ))}
              {expectedHeaders.map((header, index) => (
                <tr key={index}>
                  <td>{headersInFilex[index]}</td>
                  <td>{header}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {currentErrorValues1.length > 0 ? (
        <>
          {" "}
          <div className="error-table">
            <table>
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Column</th>
                  <th>Error Value</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {currentErrorValues1.map((error, index) => (
                  <tr key={index}>
                    <td>{error.row}</td>
                    <td>{error.column}</td>
                    <td>{error.value}</td>
                    <td>{error.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button onClick={handlePrevPage1} disabled={currentPage1 === 1}>
              Previous
            </button>
            <span>
              Page {currentPage1} of {totalPages1}
            </span>
            <button
              onClick={handleNextPage1}
              disabled={currentPage1 === totalPages1}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <></>
      )}

      {headersMatch && currentDuplicateValues.length > 0 && (
        <div className="error2-message">Duplicate Lpg_id or mobile</div>
      )}
      {currentDuplicateValues.length > 0 ? (
        <>
          {" "}
          <div className="error2-table">
            <table>
              <thead>
                <tr>
                  {Object.keys(currentDuplicateValues[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentDuplicateValues.map((item, index) => (
                  <tr key={index}>
                    {Object.values(item).map((value, innerIndex) => (
                      <td key={innerIndex}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button onClick={handlePrevPage2} disabled={currentPage2 === 1}>
              Previous
            </button>
            <span>
              Page {currentPage2} of {totalPagesDuplicate}
            </span>
            <button
              onClick={handleNextPage2}
              disabled={currentPage2 === totalPagesDuplicate}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <></>
      )}


      {DuplicateMobile && DuplicateMobile.length > 0 && (
        <div className="error2-message">Duplicate Mobile & Different Lpg ID</div>
      )}
      {DuplicateMobile && DuplicateMobile.length > 0 ? (
        <>
          {" "}
          <div className="error2-table">
            <table>
              <thead>
                <tr>
                  {Object.keys(DuplicateMobile[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {DuplicateMobile.map((item, index) => (
                  <tr key={index}>
                    {Object.values(item).map((value, innerIndex) => (
                      <td key={innerIndex}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className="pagination">
            <button onClick={handlePrevPage2} disabled={currentPage2 === 1}>
              Previous
            </button>
            <span>
              Page {currentPage2} of {totalPagesDuplicate}
            </span>
            <button
              onClick={handleNextPage2}
              disabled={currentPage2 === totalPagesDuplicate}
            >
              Next
            </button>
          </div> */}
        </>
      ) : (
        <></>
      )}
      <br />
      <br />
      <br />

      {snewData?.length > 0 && (
        <>
          <h2>The Data in these Table will be uploaded </h2>

          <h4>New Data</h4>
          <ExcelDataTable
            columns={newColumnsRef.current}
            data={snewData}
            currentPage={1}
            itemsPerPage={10}
          />
        </>
      )}
      {soldData?.length > 0 && (
        <>
          <h2>Already Registered Customers without KYC</h2>
          <ExcelDataTable
            columns={oldColumnsRef.current}
            data={soldData}
            currentPage={1}
            itemsPerPage={10}
          />
        </>
      )}
      {soldKycData?.length > 0 && (
        <>
          <h2>Already Registered Customers with KYC Done (Immutable)</h2>
          <ExcelDataTable
            columns={oldKycColumnsRef.current}
            data={soldKycData}
            currentPage={1}
            itemsPerPage={10}
          />
        </>
      )}

      {excelFileData &&
        // !(
        //   soldKycData?.length > 0 ||
        //   soldData?.length > 0 ||
        //   snewData?.length > 0
        // ) &&

        (
          <div>
            <h5>Input Table</h5>
            <div className="excel-data">
              <ExcelDataTable
                columns={columns}
                data={excelData}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
            {/* <div className="pagination">
              <button onClick={handlePrevPage} disabled={currentPage === 1}>
                Previous
              </button>
              <span>
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <h2>{kycDoneData.length}</h2>
            <h2>{kycDoneData.length}</h2>
            <h2>{kycDoneData.length}</h2>
            </div> */}
          </div>
        )}

      {(DuplicateMobile?.length < 1 && (snewData?.length > 0 || soldData?.length > 0)) && (
        <>
          <button className="upload-button" onClick={handleUpload2}>
            Upload
          </button>
          <button className="upload-button" onClick={downloadExcelwithData}
            style={{
              marginLeft: 20
            }}
          >
            Download Excel with Data
          </button>
        </>
      )}
    </div>
  );
};

export default Upload;
