import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import user_icon from "../assets/user_icon.svg";
import { default as ReactSelect } from "react-select";
import "react-calendar/dist/Calendar.css";
import { components } from "react-select";
import { Button, Pagination, Table } from "react-bootstrap";
import search_icon from "../assets/search_icon.svg";
import download_icon from "../assets/download_icon.svg";
import refresh_icon from "../assets/refresh_icon.svg";
import three_dots from "../assets/three_dots.svg";
import "./Customer.scss";
import Calendar from "react-calendar";
import axios from "axios";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { GDSRecords } from "../ENDE";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

function TableStatus({ text }) {
    const textx = text ? text.toLowerCase() : "";
    // console.log(textx);
    // console.log(textx === 'rejected');
    const color =
        textx === "rejected"
            ? "#EB5757"
            : textx === "pending"
                ? "#F2994A"
                : "#12A474";
    const bgcolor =
        textx === "rejected"
            ? "#FFDBDB"
            : textx === "pending"
                ? "#FFF3DB"
                : "#BBF3E0";
    return (
        <span
            style={{
                backgroundColor: bgcolor,
                color: color,
                fontSize: 12,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 8,
                textTransform: "capitalize",
            }}
        >
            {text}
        </span>
    );
}
function TableDeliveryandLDandUJJWALA({ text }) {
    const textx = text ? text.toLowerCase() : "";
    const color = textx === "no" || textx === "false" ? "#EB5757" : "#12A474";
    const bgcolor = textx === "no" || textx === "false" ? "#FFDBDB" : "#BBF3E0";
    return (
        <span
            style={{
                backgroundColor: bgcolor,
                color: color,
                fontSize: 12,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 8,
                textTransform: "capitalize",
            }}
        >
            {textx === "false" ? "No" : "Yes"}
        </span>
    );
}

const distributorApprovalStatusList = [
    { value: "approved", label: "Approved", isSelected: true },
    { value: "pending", label: "Pending" },
    { value: "rejected", label: "Rejected" },
];
const loanDisbursementStatusList = [
    { value: "approved", label: "Approved" },
    { value: "pending", label: "Pending" },
    { value: "rejected", label: "Rejected" },
];
const deliveryStatusList = [
    { value: "true", label: "Delivered" },
    { value: "false", label: "Not Delivered" },
];
const ccList = [
    { value: "lpg_dist_id", label: "CC Code" },
    { value: "lpg_dist_name", label: "Dist Name" },
    { value: "lpg_id", label: "LGP ID" },
    { value: "name", label: "Cust Name" },
];
const options = { day: "numeric", month: "numeric", year: "2-digit" };
const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default function SalesOfficer() {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [appVersion, setAppVersion] = useState(null);
    const [shouldFetchData, setShouldFetchData] = useState(true);

    const [distributorStatus, setDistributorStatus] = useState(null);
    const [loanDisbursementStatus, setLoanDisbursementStatus] = useState(null);
    const [deliveryStatus, setDeliveryStatus] = useState(null);
    const [selectedSearchFilter, setSelectedSearchFilter] = useState(null);
    const [isEndDateVisible, setIsEndDateVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const columns = [
        "name of sales officer",
        "so mobile no",
        "name of territory manager",
        "active distributors",
        "onboarded distributors",
        "total distributors",
        "total status approved",
        "total status pending",
        "total status rejected",
        "total disbursement approved",
        "total disbursement pending",
        "total disbursement rejected",
    ];
    const [data, setdata] = useState(null);

    const onClickSearch = () => {
        fetchData();
    };
    const handlePageChange = (value) => {
        setPage(value);
        console.log(value);
        // fetchData();
        setShouldFetchData(true);
    };
    useEffect(() => {
        // Fetch data when 'shouldFetchData' is true (indicating cache is invalidated)
        if (shouldFetchData) {
            fetchData();
        }
    }, [shouldFetchData]);

    // Use this useEffect to fetch data when other dependencies change
    useEffect(() => {
        // Fetch data when other dependencies change
        if (!shouldFetchData) {
            fetchData();
        }
    }, [page, pageSize, selectedSearchFilter /* other dependencies */]);


    const pageCount = Math.ceil(totalCount / pageSize);

    const fetchAppVersion = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_LINK}/MerchantNativeappVersion`
            );
            console.log("=======================", response.data);
            setAppVersion(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            console.log(page);
            console.log(page);
            console.log(page);
            console.log(page);

            await axios({
                url: `${process.env.REACT_APP_API_LINK}/distinctSalesOfficer?searchedValue=${searchValue}&pageNumber=${page}`,
                method: "GET",
                responseType: "json",
                // withCredentials: true,

                headers: {
                    userMobile: Cookies.get("userMobile"),
                },
            }).then((response) => {
                console.log(response);
                setdata(GDSRecords(response.data.data, apiDE));
                setTotalCount(response.data.pagination.totalRowCount);
                setShouldFetchData(false); // Set the flag to indicate data is now cached
                setIsLoading(false);
                localStorage.setItem("distinctdist", response.data);
            });
        } catch (err) {
            console.log("error fetching ");
            console.log(err);
        }
    };
    const downloaddata = async () => {
        console.log(
            `${process.env.REACT_APP_API_LINK
            }/downloaddistinctSalesOfficer?searchedValue=${searchValue}&usermobile=${Cookies.get(
                "userMobile"
            )}`
        );
        window.open(
            `${process.env.REACT_APP_API_LINK
            }/downloaddistinctSalesOfficer?searchedValue=${searchValue}&usermobile=${Cookies.get(
                "userMobile"
            )}`,
            "_blank"
        );
    };

    useEffect(() => {
        fetchData();
        fetchAppVersion();
    }, []);

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (field) => {
        if (field === sortField) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortField(field);
            setSortOrder("asc");
        }
    };
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
    }
    function TableOnboarded({ text }) {
        const textx = text ? text.toLowerCase() : "";
        // console.log(textx);
        // console.log(textx === 'rejected');
        const color = textx === "not onboarded" ? "#EB5757" : "#12A474";
        const bgcolor = textx === "not onboarded" ? "#FFDBDB" : "#BBF3E0";
        return (
            <span
                style={{
                    backgroundColor: bgcolor,
                    color: color,
                    width: "100%",
                    fontSize: 12,
                    padding: 5,
                    borderRadius: 8,
                    textTransform: "capitalize",
                }}
            >
                {text}
            </span>
        );
    }
    const fields = [
        "name of sales officer",
        "so mobile no",
        "name_of_territory_manager",
        "active distributors",
        "onboarded distributors",
        "total distributors",
        "total status approved",
        "total status pending",
        "total status rejected",
        "total disbursement approved",
        "total disbursement pending",
        "total disbursement rejected",
    ];
    const getPageItems = () => {
        const items = [];

        // Add Prev button
        items.push(
            <Pagination.Prev
                key="prev"
                onClick={() => {
                    if (page > 1) {
                        handlePageChange(page - 1);
                    }
                }}
                disabled={page <= 1}
            />
        );

        // Add page numbers
        for (
            let i = Math.max(1, page - 4);
            i <= Math.min(pageCount, page + 5);
            i++
        ) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={page === i}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        // Add Next button
        items.push(
            <Pagination.Next
                key="next"
                onClick={() => {
                    if (page < pageCount) {
                        handlePageChange(page + 1);
                    }
                }}
                disabled={page >= pageCount}
            />
        );

        return items;
    };

    return (
        <Container>
            <div className="d-flex justify-content-between" style={{ height: 100 }}>
                <div>
                    <h1 className='align-self-end' style={{ margin: 0, marginTop: '0rem' }}>Sales Officers</h1>
                    <h1 className='align-self-end' style={{ fontSize: '1.2rem', margin: 0 }}>{Cookies.get("userRole") !== "Distributor" ? Cookies.get("SortString") : Cookies.get("userCC")} </h1>

                    <br />
                </div>
                {/* <img src={user_icon} alt="" className='m-4' height={44} width={44} /> */}
                <h5 style={{ marginTop: 20 }}>App Version: {appVersion}</h5>
            </div>
            <div className="d-flex flex-wrap">
                <div className="input-group m-1" style={{ maxWidth: 600 }}>
                    <img
                        src={search_icon}
                        alt=""
                        height={44}
                        width={44}
                        className="input-group-text bg-light  border light"
                        id="basic-addon1"
                    />
                    <input
                        type="text"
                        className="form-control border light"
                        value={searchValue}
                        onChange={(v) => setSearchValue(v.target.value)}
                        placeholder="Search"
                        aria-label="search"
                        aria-describedby="basic-addon1"
                    />
                </div>
                {/* <Button variant="light border m-1" onClick={onClickSearch}>Search</Button> */}
                <img
                    src={download_icon}
                    alt=""
                    height={44}
                    width={44}
                    className="input-group-text bg-light border light m-1 my-button"
                    id="basic-addon1"
                    onClick={() => downloaddata()}
                />
                <img
                    src={refresh_icon}
                    alt=""
                    height={44}
                    width={44}
                    className="input-group-text bg-light border light m-1 my-button"
                    id="basic-addon1"
                    onClick={() => fetchData()}
                />
            </div>
            <div className="d-flex flex-wrap  justify-content-between">
                <Button variant="primary fw-bold mx-1 my-2 " onClick={onClickSearch}>
                    Search
                </Button>
            </div>

            {data != null && !isLoading ? (
                <Table
                    className="mt-3"
                    hover
                    style={{ textAlign: "center", alignItems: "center" }}
                    responsive
                >
                    <thead>
                        <tr
                            style={{
                                color: "#809FB8",
                                borderBottomWidth: 10,
                                borderBottomColor: "#b4a7d6",
                                fontWeight: "600",
                                textTransform: "uppercase",
                                height: 50,
                                verticalAlign: "middle",
                            }}
                        >
                            {columns.map((column) => (
                                <td
                                    key={column}
                                    onClick={() => {
                                        // alert(column);
                                        handleSort(column);
                                    }}
                                >
                                    {column}
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data
                            .sort((a, b) => {
                                if (!sortField) return 0;
                                const aValue = a[sortField];
                                const bValue = b[sortField];

                                if (aValue === undefined || bValue === undefined) return 0;
                                const comparison = aValue.localeCompare(bValue);
                                return sortOrder === "asc" ? comparison : -comparison;
                            })
                            .map((item, index) => (
                                <tr key={index}>
                                    {fields.map((field, fieldindex) => (
                                        <td
                                            key={`${fieldindex}`}
                                            style={{
                                                color: "#06152B",
                                                fontWeight: "400",
                                                fontSize: "0.81rem",
                                            }}
                                        >
                                            {field !== "onboarded" ? (
                                                item[field] && item[field] !== "null" ? (
                                                    item[field]
                                                ) : (
                                                    "N/A"
                                                )
                                            ) : (
                                                <TableOnboarded text={item[field]} />
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                    {/* <h1>asdkjnaskds</h1> */}
                </Table>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Spinner animation="border" />
                </div>
            )}
            <div>
                {/* Math.ceil(totalCount / pageSize) */}
                <Pagination style={{ float: "right" }}>
                    <Pagination.First onClick={() => handlePageChange(1)} />
                    {getPageItems()}
                    <Pagination.Last onClick={() => handlePageChange(pageCount)} />
                </Pagination>
            </div>
        </Container>
    );
}
