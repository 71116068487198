import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

function ChangePasswordModal() {
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [userName, setUserName] = useState("");

  const [isEmailCodeCorrect, setIsEmailCodeCorrect] = useState(false);
  const [isUsernameSent, setIsUsernameSent] = useState(false);

  const handleClose = () => {
    setShow(false);
    setIsEmailCodeCorrect(false);
    setIsUsernameSent(false);
    setPassword("");
    setEmailCode("");
    setConfirmPassword("");
    setUserName("");
  };
  const handleShow = () => setShow(true);

  const handlePasswordChange = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    alert("Passwords change will continue");
    // Call your function to handle password change here
    console.log("Password changed to: ", password);
    handleClose();
  };

  const sendEmail = () => {
    setIsUsernameSent(true);
  };
  const SubmitEmailCode = () => {
    if (emailCode === "correct-code") {
      setEmailCode("");
      setIsEmailCodeCorrect(true);
    } else {
      setIsEmailCodeCorrect(false);
    }
  };

  return (
    <>
      <p className="forgetpassword" onClick={handleShow}>
        Forget Password?
      </p>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handlePasswordChange}>
            {!isEmailCodeCorrect ? (
              isUsernameSent ? (
                <Form.Group controlId="formEmailCode">
                  <Form.Label>Email Code</Form.Label>
                  <Form.Control
                    type="text"
                    value={emailCode}
                    onChange={(e) => setEmailCode(e.target.value)}
                    placeholder="Enter email code"
                  />
                  <Button className="fancy-button" onClick={SubmitEmailCode}>
                    Submit
                  </Button>
                </Form.Group>
              ) : (
                <Form.Group controlId="formUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Enter username"
                  />
                  <Button className="fancy-button" onClick={sendEmail}>
                    Submit
                  </Button>
                </Form.Group>
              )
            ) : (
              <>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    autocomplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formConfirmPassword">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm new password"
                    autocomplete="off"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
                <Button
                  className="fancy-button"
                  variant="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
