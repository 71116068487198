import React, { useState, useEffect } from "react";
import axios from "axios";
import { GDSRecords } from "../ENDE";
import Cookies from 'js-cookie';
import Calendar from "react-calendar";
import "./Charts.scss";
import Chart from "chart.js/auto";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

export default function Charts() {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date("2020-06-30"));
  const [endDate, setEndDate] = useState(new Date());
  const [isEndDateVisible, setIsEndDateVisible] = useState(false);
  const [isStartDateVisible, setIsStartDateVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [stats2, setStats2] = useState([]);

  const onStartDateChange = (value) => {
    setStartDate(value);
    setIsStartDateVisible(!isStartDateVisible);
  };

  const onEndDateChange = (value) => {
    setEndDate(value);
    setIsEndDateVisible(!isEndDateVisible);
  };

  const fetchdata = async () => {
    try {
      setLoading(true);
      const response = await axios({
        url: `${process.env.REACT_APP_API_LINK}/GreattrStats/`,
        method: "GET",
        responseType: "json",
        headers: {
          startDate: new Date(
            startDate.getTime() - startDate.getTimezoneOffset() * 60000
          )
            .toISOString()
            .substring(0, 10),
          endDate: new Date(
            endDate.getTime() - endDate.getTimezoneOffset() * 60000
          )
            .toISOString()
            .substring(0, 10),
          userMobile: Cookies.get("userMobile"),
        },
      });

      const responseData = response.data;

      setStats(responseData);
      setLoading(false);
    } catch (err) {
      console.log("error fetching ");
      console.log(err);
      setLoading(false);
    }
  };

  const fetchChart2 = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_LINK}/distinctOverdueStat?searchedValue=${searchValue}`,
        {
          headers: {
            "userMobile": Cookies.get("userMobile"),
          }
        }
      );
      let responseData2 = response.data;

      if (Array.isArray(responseData2.data)) {
        responseData2 = responseData2.data;
      } else if (typeof responseData2 === "object") {
        responseData2 = [responseData2];
      }

      const decryptedData = responseData2
        .map((item) => {
          let decryptedItem = GDSRecords(item, apiDE);

          if (
            decryptedItem.lpg_dist_name === "Shardul GAs agency" ||
            decryptedItem.lpg_dist_name === "michto gas agency" ||
            decryptedItem.lpg_dist_name === "null"
          ) {
            return null; // Exclude the object with lpg_dist_name equal to "Shardul GAs agency"
          }

          return decryptedItem;
        })
        .filter((item) => item !== null); // Filter out the excluded items (null values)

      // Log decrypted data and labels
      console.log("Decrypted Data:", decryptedData);

      // Extract labels and data values from the decrypted data
      const labels = decryptedData.map((item) => item.lpg_dist_name);
      const dataValues = decryptedData.map((item) => item.overdue_count);

      // Log labels and dataValues before passing to fetchChart2
      console.log("Labels:", labels);
      console.log("Data Values:", dataValues);

      // Update the second chart with decrypted data
      setStats2(decryptedData); // Assuming you have a state for second chart data
      setLoading(false);
    } catch (err) {
      console.log("error fetching data2:", err);
    }
  };

  useEffect(() => {
    fetchdata();
    fetchChart2(); // Fetch data for the second chart
  }, [startDate, endDate]);

  useEffect(() => {
    if (stats.length > 0) {
      // Generate the first chart
      const ctx = document.getElementById("myChart");
      const myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Total Loans",
            "Loans Approved",
            "Loans Pending",
            "Loans Rejected",
          ],
          datasets: [
            {
              label: "Loan Counts",
              data: [
                stats[0]?.[0]?.["Total_loans"] || 0,
                stats[4]?.[0]?.["Loans_approved"] || 0,
                stats[5]?.[0]?.["Loans_pending"] || 0,
                stats[10]?.[0]?.["Loans_rejected"] || 0,
              ],
              backgroundColor: [
                "rgba(75, 192, 192, 0.6)",
                "rgba(54, 162, 235, 0.6)",
                "rgba(255, 206, 86, 0.6)",
                "rgba(255, 99, 132, 0.6)",
              ],
              borderColor: [
                "rgba(75, 192, 192, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(255, 99, 132, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true, // Add this line
          maintainAspectRatio: false,
        },
      });

      // Cleanup the previous chart instance before rendering a new one
      return () => {
        myChart.destroy();
      };
    }
  }, [stats]);

  useEffect(() => {
    if (stats2.length > 0) {
      // Generate the second chart
      const ctx2 = document.getElementById("mySecondChart");
      const mySecondChart = new Chart(ctx2, {
        type: "bar",
        data: {
          labels: stats2.map(item => item.lpg_dist_name),
          datasets: [
            {
              label: "Overdue Count",
              data: stats2.map(item => item.overdue_count),
              backgroundColor: "rgba(75, 192, 192, 0.6)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {

          responsive: true, // Add this line
          maintainAspectRatio: false, // Add this line

        },
      });

      // Cleanup the previous chart instance before rendering a new one
      return () => {
        mySecondChart.destroy();
      };
    }
  }, [stats2]);

  return (
    <div>
      <h2 style={{ paddingLeft: 10, paddingTop: 15 }}>Data for Loans:</h2>
      <div className="chart-container" style={{ display: "flex" }}>
        <canvas id="myChart" width="400" height="400"></canvas>
      </div>

      <h2 style={{ paddingLeft: 10, paddingTop: 15 }}>Data for Distributor wise Overdue EMI's:</h2>
      <div className="chart-container" style={{ display: "flex" }}>
        <canvas id="mySecondChart" width="400" height="400"></canvas>
      </div>
    </div>
  );
}


