import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { GDSRecords } from "../ENDE";
import "./DistributorData.scss"; // Assuming you have a CSS file for styling
import search_icon from '../assets/search_icon.svg';
import download_icon from '../assets/download_icon.svg';
import refresh_icon from '../assets/refresh_icon.svg';
import { Button } from "react-bootstrap";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

const DistributorData = () => {
  const [chartData, setChartData] = useState([]);
  const [distributorData, setDistributorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8); // Display 8 items per page
  const onClickSearch = () => {
    fetchData();
  }
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    fetchData()
  }, [currentPage, itemsPerPage]);
  const fetchData = async () => {
    // Create an object to send as the request body
    const requestBody = {
      page: currentPage,
      perPage: itemsPerPage,
      searchValue: searchValue
    };
    const headers = {
      "userMobile": Cookies.get("userMobile"), // Add your custom header here
    };
    axios
      .post(`${process.env.REACT_APP_API_LINK}/distributor_status_count`, requestBody, { headers })
      .then((response) => {
        const data = GDSRecords(response.data, apiDE);

        const newChartData = data.map((item) => ({
          labels: ["Total", "Approved", "Pending", "Rejected", "Disbursed"],
          datasets: [
            {
              label: "Count",
              data: [
                item.total_count,
                item.approved_count,
                item.pending_count,
                item.rejected_count,
                item.disbursed_count,
              ],
              backgroundColor: [
                "rgba(75,192,192,0.2)",
                "rgba(192,75,192,0.2)",
                "rgba(192,192,75,0.2)",
                "rgba(75,192,75,0.2)",
                "rgba(75,192,75,0.2)",
              ],
              borderColor: [
                "rgba(75,192,192,1)",
                "rgba(192,75,192,1)",
                "rgba(192,192,75,1)",
                "rgba(75,192,75,1)",
                "rgba(75,192,75,1)",
              ],
              borderWidth: 1,
            },
          ],
        }));

        setChartData(newChartData);
        setDistributorData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));


  }
  return (
    <div className="distributor-container">

      {/* <h2 style={{
        paddingLeft: '10%'
        , paddingTop: '3%',
      }}>Distributor Graphs</h2> */}
      <div style={{ paddingLeft: 112 }}>
        <h1 className='align-self-end' style={{ margin: 0, marginTop: '0rem' }}>Distributor Graphs</h1>
        <h1 className='align-self-end' style={{ fontSize: '1.2rem', margin: 0 }}>{Cookies.get("userRole") !== "Distributor" ? Cookies.get("SortString") : Cookies.get("userCC")} </h1>

        <br />
      </div>

      <div className='d-flex flex-wrap' style={{
        paddingLeft: '10%'
        , paddingTop: '0%'
      }}>
        <div className="input-group m-1" style={{ maxWidth: 600 }}>
          <img src={search_icon} alt="" height={44} width={44} className="input-group-text bg-light  border light" id="basic-addon1" />
          <input type="text" className="form-control border light" value={searchValue} onChange={v => setSearchValue(v.target.value)} placeholder="Search with Distributor ID" aria-label="search" aria-describedby="basic-addon1" />
        </div>

        <img src={refresh_icon} alt="" height={44} width={44}
          className="input-group-text bg-light border light m-1 my-button" id="basic-addon1"
          onClick={() => fetchData()} />
        <Button variant="primary fw-bold mx-1 my-2 " onClick={onClickSearch}>Search</Button>
      </div>
      <div className='d-flex flex-wrap  justify-content-between'>

      </div>
      <div className="distributor-container2">



        {distributorData.map((distributor, index) => (
          <div className="card" key={`distributor-${index}`}>
            <div className="info">
              <h2>Distributor ID: {distributor.lpg_dist_id}</h2>
              <p>Name: {distributor.lpg_dist_name}</p>
            </div>
            <div className="graph-container">
              <Bar data={chartData[index]} />
            </div>
          </div>
        ))}
      </div>
      {/* Pagination Controls */}
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous Page
        </button>
        <span>Page {currentPage}</span>
        <button onClick={() => setCurrentPage(currentPage + 1)}>
          Next Page
        </button>
      </div>
    </div>
  );
};

export default DistributorData;
